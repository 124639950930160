const DASHBOARD = (isActive = false) => {
  return {
    path: '/p/s',
    text: 'Dashboard',
    isActive
  }
}

const CLASS_LIST = (isActive = false) => {
  return {
    path: '/p/s/turmas',
    text: 'Turmas',
    isActive
  }
}

const CLASS = (_class, isActive = false) => {
  return {
    path: `/p/s/turmas/${_class.id}`,
    text: 'Turma',
    isActive
  }
}

const ACTIVITY = (activity, isActive = false) => {
  return {
    path: `/p/s/atividades/${activity.id}`,
    text: 'Atividade',
    isActive
  }
}

export {
  DASHBOARD,
  CLASS,
  CLASS_LIST,
  ACTIVITY
}
