<template>
  <layout page="student-activity">
    <page-title icon="lni lni-write" :breadcrumb="breadcrumb">{{ activity.name }}</page-title>
    <div class="columns">
      <div class="column is-one-third">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <p class="mb-0">
                <strong>Anexo: </strong>
                <a
                  v-if="activity.anex"
                  :href="`${API_URL}/public/activities/annexes/${activity.anex}`"
                  target="__blank"
                >
                  {{ activity.anex }}
                </a>
                <span v-else><i>sem anexo</i></span>
              </p>
              <p>
                <strong>Criada Em: </strong>
                {{ activity.createdAt | formatDate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Descrição
            </p>
          </header>
          <div class="card-content">
            <div class="content" v-html="activity.desc">
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { format } from 'date-fns'
import marked from 'marked'
import api from '@/api'
import { DASHBOARD, CLASS_LIST, ACTIVITY } from '@/config/breadcrumb/student'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'StudentActivity',
  components: {
    Layout,
    PageTitle
  },
  created () {
    this.activity.id = this.$route.params.id

    api.get(`/activities/${this.activity.id}`).then(res => {
      if (res.status === 404) {
        this.$router.push('/p/s')
      }

      this.activity = res.data
      if (this.activity.desc !== '') {
        this.activity.desc = marked(this.activity.desc)
      }
    })
  },
  data () {
    return {
      activity: {
        id: 0,
        name: 'Carregando...',
        desc: ''
      }
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD(),
        CLASS_LIST(),
        ACTIVITY(this.activity, true)
      ]
    }
  },
  filters: {
    formatDate (value) {
      if (value === '' || value === undefined) return
      return format(new Date(value), 'd/MM/y')
    }
  }
}
</script>
